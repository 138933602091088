import { ENDPOINT, HTTP } from "../../const/http";
import { useInvoiceStore } from "../../stores/invoice";
import { constructAuthOptions, useApi } from "./common";
import { reportError } from "../utils/telemetry";

export const getInvoiceDetail = async (invoiceId) => {
    const { data, fetchApi, statusCode } = useApi({
        optionsHandler: constructAuthOptions,
    });
    const store = useInvoiceStore();

    try {
        store.setLoading(true);
        await fetchApi({
            endPoint: ENDPOINT.GET_INVOICE_DETAIL(invoiceId),
            method: HTTP.GET,
        });

        store.setStatusCode(statusCode);

        if (data.error) {
            store.setError(data.error);
            store.setInvoice(null);
        } else {
            store.setError(null);
            store.setInvoice(data.value);
        }
    } catch (error) {
        reportError(error);
        store.setError(error);
        store.setInvoice(null);
    } finally {
        store.setLoading(false);
    }
};

export const updateInvoiceDetail = async (invoiceId, details) => {
    const { fetchApi } = useApi({
        optionsHandler: constructAuthOptions,
        toastMgs: false, // Suppress error toast
    });
    await fetchApi({
        endPoint: ENDPOINT.UPDATE_INVOICE_DETAIL(invoiceId),
        method: HTTP.POST,
        payload: details,
    });
};
