<script setup>
import { onBeforeMount, onUnmounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import SectionHeader from "../components/texts/SectionHeader.vue";
import { BASE_PATH } from "../const/route";
import { formatCurrency } from "../services/utils/formatter";
import BaseView from "./BaseView.vue";
import { useInvoiceStore } from "../stores/invoice";

const route = useRoute();
const router = useRouter();
const invoice = ref(null);
const invoiceStore = useInvoiceStore();

onBeforeMount(() => {
    const sessionId = route.query.sessionId;
    const invoiceDetails = localStorage.getItem(sessionId);

    if (invoiceStore.invoice.payment_status === "APPROVED") {
        invoice.value = invoiceStore.invoice;
    } else if (invoiceDetails) {
        invoice.value = JSON.parse(invoiceDetails);
    } else {
        router.replace(BASE_PATH);
    }
});

onUnmounted(() => {
    localStorage.clear();
});
</script>

<template>
    <BaseView>
        <div class="self-center text-center my-16">
            <SectionHeader
                label="Thank you for your payment"
                :show-divider="false"
                class="mb-8"
            />
            <p v-if="invoice && invoice.billing">
                Amount paid:
                {{ formatCurrency(invoice.billing.total) }}
            </p>
            <p v-if="invoice" class="mt-2 mb-8">Reference: {{ invoice.id }}</p>
            <router-link to="/" replace>Return to Home page</router-link>
        </div>
    </BaseView>
</template>
