<script setup>
import { onMounted, reactive } from "vue";
import CustomerSummary from "../components/sections/CustomerSummary.vue";
import PaymentConfirmation from "../components/sections/PaymentConfirmation.vue";
import CostSummary from "../components/sections/CostSummary.vue";
import InvoiceTitle from "../components/layout/InvoiceTitle.vue";
import LoadingBackdrop from "../components/utils/LoadingBackdrop.vue";

import BaseView from "./BaseView.vue";
import { useInvoiceStore } from "../stores/invoice";
import { useRoute, useRouter } from "vue-router";
import { getInvoiceDetail } from "../services/apis/invoice";
import { useAuth } from "../services/firebase";
import GenericErrorView from "./GenericErrorView.vue";
import SectionHeader from "../components/texts/SectionHeader.vue";
import { BASE_PATH, PAYMENT_SUCCESSFUL_PATH } from "../const/route";
import { reportError } from "../services/utils/telemetry";

const { getCurrentUser } = useAuth();
const route = useRoute();
const router = useRouter();
const invoiceStore = useInvoiceStore();

const state = reactive({
    error: undefined,
});

onMounted(() => {
    const invoiceId = route.params.id.toUpperCase();
    invoiceStore.setInvoiceField("id", invoiceId);
    invoiceStore.setLoading(true);

    getInvoiceDetail(invoiceId)
        .then(async () => {
            const currentUser = await getCurrentUser();

            if (currentUser) {
                invoiceStore.setCustomerField("email", currentUser.email);
            }
            localStorage.setItem("currentInvoice", invoiceId);
            if (invoiceStore.invoice.payment_status === "APPROVED") {
                router.push({ name: PAYMENT_SUCCESSFUL_PATH });
            }
        })
        .catch((error) => {
            state.error = error;
            reportError(error);

            if (invoiceStore.statusCode.value === 404) {
                router.push({ name: BASE_PATH });
            }
        })
        .finally(() => {
            invoiceStore.setLoading(false);
        });
});
</script>

<template>
    <LoadingBackdrop v-if="invoiceStore.loading" />
    <BaseView
        v-else-if="
            state.error || invoiceStore.error || !invoiceStore.invoice.billing
        "
    >
        <GenericErrorView
            error-msg="Failed to load invoice, please contact us if the problem persists."
        />
    </BaseView>
    <BaseView v-else-if="invoiceStore.invoice.billing.total <= 0">
        <div class="self-center text-center my-16">
            <SectionHeader
                label="No payment required"
                :show-divider="false"
                class="mb-8"
            />
            <p class="mb-8">Reference: {{ invoiceStore.invoice.id }}</p>
            <router-link to="/" replace>Return to Home page</router-link>
        </div>
    </BaseView>
    <BaseView v-else>
        <div class="flex lg:flex-row flex-col">
            <div
                class="lg:w-8/12 w-full xl:px-16 sm:px-8 lg:pb-14 px-6 md:py-8 py-4"
            >
                <InvoiceTitle class="mt-8 my-8" />
                <CustomerSummary class="mb-4" />
            </div>
            <div
                class="lg:w-4/12 w-full bg-bluesteel-tint-5 dark:bg-gray-900 flex flex-col lg:mt-4 lg:px-8 md:px-7 px-4 lg:py-20 md:py-10 py-6 justify-between"
            >
                <div>
                    <CostSummary class="mb-4" />
                </div>
                <PaymentConfirmation />
            </div>
        </div>
    </BaseView>
</template>
