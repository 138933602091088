import {
    BASE_PATH,
    NOT_FOUND_PATH,
    INVOICE_PATH,
    SIGN_IN_PATH,
    PAYMENT_SUCCESSFUL_PATH,
    PAYMENT_ERROR_PATH,
    PAYMENT_CANCEL_PATH,
    PAYMENT_PATH,
} from "../const/route";
import NotFound from "../views/NotFoundView.vue";
import Home from "../views/HomeView.vue";
import Invoice from "../views/InvoiceView.vue";
import PaymentSuccessful from "../views/PaymentSuccessfulView.vue";
import PaymentError from "../views/PaymentErrorView.vue";
import PaymentCancelled from "../views/PaymentCancelledView.vue";

export const routes = [
    {
        path: `${BASE_PATH}${PAYMENT_PATH}/${PAYMENT_SUCCESSFUL_PATH}`,
        name: PAYMENT_SUCCESSFUL_PATH,
        component: PaymentSuccessful,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: `${BASE_PATH}${PAYMENT_PATH}/${PAYMENT_ERROR_PATH}`,
        name: PAYMENT_ERROR_PATH,
        component: PaymentError,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: `${BASE_PATH}${PAYMENT_PATH}/${PAYMENT_CANCEL_PATH}`,
        name: PAYMENT_CANCEL_PATH,
        component: PaymentCancelled,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: `${BASE_PATH}${INVOICE_PATH}/:id`,
        name: INVOICE_PATH,
        component: Invoice,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: BASE_PATH,
        name: BASE_PATH,
        component: Home,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: `${BASE_PATH}:pathMatch(.*)`,
        name: NOT_FOUND_PATH,
        component: NotFound,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: `${BASE_PATH}${SIGN_IN_PATH}`,
        name: SIGN_IN_PATH,
        component: () => import("../views/SignInView.vue"),
    },
];
