export const parseWindcaveSession = (data) => {
    const paymentUrl = data.links.find((link) => link.rel === "hpp")?.href;

    if (!paymentUrl) throw new Error("Payment URL not found.");

    return {
        sessionId: data.id,
        paymentUrl,
    };
};
