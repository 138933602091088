import { DEFAULT_CURRENCY, DEFAULT_LOCALE } from "../../const/config";

export const formatCurrency = (val) => {
    return val
        .toLocaleString(DEFAULT_LOCALE, {
            style: "currency",
            currency: DEFAULT_CURRENCY,
        })
        .replace("$", "$ ");
};

export const toTitleCase = (str) => {
    return str.replace(
        /^[a-z]|[A-Z]/g,
        (c, i) => (i ? " " : "") + c.toUpperCase()
    );
};

export const formatPostcode = (postcode) => {
    return `${postcode.toString().length < 4 ? "0" : ""}${postcode}`;
};

export const formatExpiryMonth = (month) => {
    return month < 10 ? `0${month}` : month.toString();
};
