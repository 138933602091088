<script setup>
import ErrorCard from "../components/cards/ErrorCard.vue";
import SectionHeader from "../components/texts/SectionHeader.vue";

const props = defineProps({
    errorTitle: { type: String, default: "Unexpected error" },
    errorMsg: { type: String, default: "An unexpected error has occurred." },
});
</script>

<template>
    <div class="self-center text-center my-16">
        <ErrorCard class="mb-8">
            <SectionHeader
                :label="props.errorTitle"
                :show-divider="false"
                class="mb-8"
            />
            <p>
                {{ props.errorMsg }}
            </p>
        </ErrorCard>
        <router-link to="/" replace>Return to Home page</router-link>
    </div>
</template>
