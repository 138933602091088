<script setup>
import {
    hasCreditCardDetails,
    hasCustomerDetails,
} from "../../services/utils/invoice";
import ButtonBase from "./ButtonBase.vue";
import { useInvoiceStore } from "../../stores/invoice";
import { PAYMENT_METHODS } from "../../const/invoice";

const props = defineProps({
    onConfirm: { type: Function, required: true },
});

const invoiceStore = useInvoiceStore();
</script>

<template>
    <ButtonBase
        class="peppermint-button focus-effect font-medium"
        label="Proceed to Payment"
        type="submit"
        :disabled="
            !hasCustomerDetails(invoiceStore.invoice.customer) &&
            invoiceStore.invoice.paymentMethod === PAYMENT_METHODS.card &&
            !hasCreditCardDetails(invoiceStore.invoice.cardDetails)
        "
        :on-click="props.onConfirm"
    />
</template>
