<script setup>
import { computed, onMounted, onUnmounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import UtilityButton from "../components/buttons/UtilityButton.vue";
import ErrorCard from "../components/cards/ErrorCard.vue";
import TextInput from "../components/form/TextInput.vue";
import FullscreenModal from "../components/layout/FullscreenModal.vue";
import SectionHeader from "../components/texts/SectionHeader.vue";
import { INVOICE_PATH } from "../const/route";
import { useInvoiceStore } from "../stores/invoice";
import BaseView from "./BaseView.vue";

const router = useRouter();
const invoiceId = ref("");
const state = reactive({
    showModal: false,
});
const invoiceStore = useInvoiceStore();
const REFERENCE_LENGTH = 14; // 12 alphanumeric characters + 2 dashes

onMounted(() => {
    window.addEventListener("keydown", closeModalOnEsc);
});

onUnmounted(() => {
    window.removeEventListener("keydown", closeModalOnEsc);
});

const closeModalOnEsc = (ev) => {
    if (ev.key === "Escape") {
        state.showModal = false;
    }
};

const navigateToInvoice = (ev) => {
    ev.preventDefault();
    router.push(`/${INVOICE_PATH}/${displayInvoiceId.value}`);
};

const filterInput = (ev) => {
    // Allow operational keys
    if ([2, 3, 8, 37, 39, 127].includes(ev.keyCode)) {
        return;
    }
    // Only accepts alphanumeric
    if (!ev.key || !ev.key.match(/^[a-zA-Z0-9]*$/)) {
        ev.preventDefault();
    }
};

const displayInvoiceId = computed({
    get() {
        return invoiceId.value;
    },
    set(newValue) {
        const formatted = (
            newValue
                .split("-")
                .join("")
                .match(/.{1,4}/g) ?? []
        )
            .join("-")
            .toUpperCase();
        invoiceId.value = formatted;
        return formatted;
    },
});
</script>

<template>
    <BaseView>
        <ErrorCard
            v-if="
                invoiceStore.statusCode && invoiceStore.statusCode.value === 404
            "
            class="self-center text-center mb-8 md:mx-16 sm:mx-8 mx-4 mt-32"
        >
            <SectionHeader
                label="Invoice Not found"
                :show-divider="false"
                class="mb-8"
            />
            <p>
                The invoice [
                <span class="font-bold">{{ invoiceStore.invoice.id }}</span> ]
                could not be found.
            </p>
            <p class="mb-4">
                You can find the reference number from your invoice (refer to
                screenshot below).
            </p>
            <button @click="() => (state.showModal = true)">
                <img
                    class="h-auto md:max-w-xl mx-auto text-center"
                    src="../assets/invoice_tutorial.png"
                    alt=""
                />
            </button>
        </ErrorCard>
        <FullscreenModal
            v-if="state.showModal"
            :onClose="() => (state.showModal = false)"
        >
            <img
                class="h-auto max-w-full"
                src="../assets/invoice_tutorial.png"
                alt=""
            />
        </FullscreenModal>

        <form class="self-center text-center mb-16 w-3/4 sm:w-1/2 lg:w-1/4">
            <TextInput
                id="invoice_id"
                label="Enter invoice reference"
                v-model="displayInvoiceId"
                autofocus
                required
                :maxlength="REFERENCE_LENGTH"
                @keydown="filterInput"
                class="uppercase"
            />

            <UtilityButton
                label="Submit"
                type="submit"
                :disabled="displayInvoiceId.trim().length === 0"
                :on-click="navigateToInvoice"
                class="mt-8"
            />
        </form>
    </BaseView>
</template>
