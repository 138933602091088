<script setup>
import InvoiceCostRow from "./InvoiceCostRow.vue";
import { useInvoiceStore } from "../../stores/invoice";
import { TAX_RATE } from "../../const/invoice";

const invoiceStore = useInvoiceStore();
</script>

<template>
    <div class="mx-auto md:px-4">
        <div class="flex flex-col">
            <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full sm:px-6">
                    <div class="overflow-x-auto">
                        <table
                            class="min-w-full table-auto"
                            aria-describedby="Summary of invoice costs"
                        >
                            <th class="p-0"></th>
                            <tbody>
                                <InvoiceCostRow
                                    label="Subtotal"
                                    :amount="
                                        invoiceStore.invoice.billing.subtotal
                                    "
                                    force-display
                                />
                                <InvoiceCostRow
                                    :label="`GST (${TAX_RATE * 100}%)`"
                                    retain-label-case
                                    :amount="invoiceStore.invoice.billing.gst"
                                    force-display
                                />
                                <InvoiceCostRow
                                    label="Grand Total"
                                    bold-label
                                    bold-amount
                                    :amount="invoiceStore.invoice.billing.total"
                                    force-display
                                />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
