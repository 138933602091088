<script setup>
import { formatCurrency, toTitleCase } from "../../services/utils/formatter";

const props = defineProps({
    label: { type: String, required: true },
    amount: { type: Number, required: true },
    forceDisplay: { type: Boolean },
    retainLabelCase: { type: Boolean },
    boldLabel: { type: Boolean },
    boldAmount: { type: Boolean },
    indent: { type: Boolean },
});
</script>

<template>
    <tr v-if="props.forceDisplay || props.amount > 0">
        <td
            class="p-2"
            :class="{ 'font-bold': props.boldLabel, 'pl-8': props.indent }"
        >
            {{ retainLabelCase ? props.label : toTitleCase(props.label) }}
        </td>
        <td class="p-2 text-end" :class="{ 'font-bold': props.boldAmount }">
            {{ formatCurrency(props.amount ?? 0) }}
        </td>
    </tr>
</template>
