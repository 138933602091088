<script setup>
const props = defineProps({
    type: { type: String, required: false, default: "button" },
    label: { type: String, required: true },
    onClick: { type: Function, required: true },
    tooltip: { type: String },
    disabled: { type: Boolean, required: false, default: false },
});
</script>

<template>
    <button
        :type="props.type"
        @click="props.onClick"
        :disabled="props.disabled"
        class="standard-button disabled:cursor-not-allowed font-medium"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        :title="props.tooltip || props.label"
    >
        <slot></slot>
        {{ props.label }}
    </button>
</template>
