<script setup>
import { ref, watch } from "vue";
import { useInvoiceStore } from "../../stores/invoice";
import TextInput from "../form/TextInput.vue";

const invoiceStore = useInvoiceStore();

const companyName = ref("");
const firstName = ref("");
const lastName = ref("");
const contactPhone = ref("");

watch(
    companyName,
    (val, _) => invoiceStore.setCustomerField("company_name", val),
    {
        immediate: true,
    }
);
watch(firstName, (val, _) => invoiceStore.setCustomerField("first_name", val), {
    immediate: true,
});
watch(lastName, (val, _) => invoiceStore.setCustomerField("last_name", val), {
    immediate: true,
});
watch(contactPhone, (val, _) => invoiceStore.setCustomerField("phone", val), {
    immediate: true,
});
</script>

<template>
    <form class="grid sm:grid-cols-2 grid-cols-1 gap-4">
        <TextInput
            id="company_name"
            label="Company Name"
            v-model="companyName"
            :required="true"
        />
        <TextInput
            id="contact_phone"
            label="Contact Phone"
            v-model="contactPhone"
            :required="true"
        />
        <TextInput
            id="first_name"
            label="First Name"
            v-model="firstName"
            :required="true"
        />
        <TextInput
            id="last_name"
            label="Last Name"
            v-model="lastName"
            :required="true"
        />
    </form>
</template>
