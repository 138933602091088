<script setup>
import { onBeforeMount, onUnmounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import ErrorCard from "../components/cards/ErrorCard.vue";
import SectionHeader from "../components/texts/SectionHeader.vue";
import { BASE_PATH } from "../const/route";
import BaseView from "./BaseView.vue";

const route = useRoute();
const router = useRouter();
const invoice = ref(null);

onBeforeMount(() => {
    const sessionId = route.query.sessionId;
    const invoiceDetails = localStorage.getItem(sessionId);

    if (!invoiceDetails) {
        router.replace(BASE_PATH);
    } else {
        invoice.value = JSON.parse(invoiceDetails);
    }
});

onUnmounted(() => {
    localStorage.clear();
});
</script>

<template>
    <BaseView>
        <div class="self-center text-center my-16">
            <ErrorCard class="mb-8">
                <SectionHeader
                    label="Unexpected error"
                    :show-divider="false"
                    class="mb-8"
                />
                <p>
                    An unexpected error has occurred while processing your
                    payment.
                </p>
                <p v-if="invoice" class="mt-4">Reference: {{ invoice.id }}</p>
            </ErrorCard>
            <router-link to="/" replace>Return to Home page</router-link>
        </div>
    </BaseView>
</template>
