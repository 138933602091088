export const HTTP = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
};

const ociGateway = import.meta.env.VITE_OCI_GATEWAY_URL;

export const ENDPOINT = {
    // GET /invoice/{id}/partial => Get invoice brief by its reference
    GET_INVOICE_DETAIL: (invoiceId) =>
        `${ociGateway}/invoice/${invoiceId}/partial`,
    // POST /invoice/{id}/payer => Add or update invoice transactional contact
    UPDATE_INVOICE_DETAIL: (invoiceId) =>
        `${ociGateway}/invoice/${invoiceId}/payer`,
    // GET /invoice/{id}/payment-session => Get the Windcave session
    GET_PAYMENT_SESSION: (invoiceId) =>
        `${ociGateway}/invoice/${invoiceId}/payment-session`,
};

export const HTTP_CODES = {
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    SERVER_ERROR: 500,
};
