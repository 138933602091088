import { ENDPOINT, HTTP } from "../../const/http";
import { constructOptions, constructAuthOptions, useApi } from "./common";

export const getWindcaveSession = async (invoiceId) => {
    const { data, fetchApi } = useApi({
        optionsHandler: constructAuthOptions,
        resultGetter: async (response) => {
            const rawData = await response.text();

            if (rawData && rawData.trim().length > 0) {
                return JSON.parse(rawData);
            }
            return null;
        },
    });
    await fetchApi({
        endPoint: ENDPOINT.GET_PAYMENT_SESSION(invoiceId),
        method: HTTP.GET,
    });
    return data.value;
};

export const sendPayment = async (paymentDetails) => {
    const { data, fetchApi } = useApi({
        optionsHandler: constructOptions,
    });
    await fetchApi({
        endPoint: paymentDetails.paymentUrl,
        method: HTTP.POST,
        payload: paymentDetails.payload,
    });
    return data.value;
};
