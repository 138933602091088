import { isEmptyString } from "./string";

export const hasCustomerDetails = (customerDetails) => {
    return (
        customerDetails &&
        !isEmptyString(customerDetails.company_name) &&
        !isEmptyString(customerDetails.first_name) &&
        !isEmptyString(customerDetails.last_name) &&
        !isEmptyString(customerDetails.phone)
    );
};

export const hasCreditCardDetails = (cardDetails) => {
    return (
        cardDetails &&
        !isEmptyString(cardDetails.cardholderName) &&
        !isEmptyString(cardDetails.cardNumber) &&
        !isEmptyString(cardDetails.expiryMonth.toString()) &&
        !isEmptyString(cardDetails.expiryYear.toString()) &&
        !isEmptyString(cardDetails.cvc)
    );
};
