import firebaseApp from "./init";
import {
    getAuth,
    signInWithEmailLink,
    signOut,
    isSignInWithEmailLink,
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { reactive } from "vue";
import { AUTH_UNKNOWN } from "../../const/auth";
import { SIGN_IN_PATH } from "../../const/route";
import { reportError, setTelemetryUser } from "../../services/utils/telemetry";

const auth = getAuth(firebaseApp);
const authState = reactive({
    auth,
    authenticated: AUTH_UNKNOWN,
    loading: true,
});
const functions = getFunctions(firebaseApp, "australia-southeast1");

const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsub = auth.onAuthStateChanged((user) => {
            authState.loading = authState.loading
                ? !authState.loading
                : authState.loading;

            if (user) {
                setTelemetryUser({
                    email: user.email,
                    id: user.uid,
                    name: user.displayName,
                });
            }

            unsub();
            resolve(user);
        }, reject);
    });
};

const signIn = (email) => {
    return signInWithEmailLink(auth, email, window.location.href);
};

const requestEmailLink = async (email, redirectUrl) => {
    if (email) {
        const getPaymentSignInLink = httpsCallable(
            functions,
            "getPaymentSignInLink"
        );
        await getPaymentSignInLink({ email, redirectUrl });
        window.localStorage.setItem("emailForSignIn", email);
    }
};

const isEmailSignInLink = (url) => {
    return isSignInWithEmailLink(auth, url);
};

const isUserSignedIn = async () => {
    try {
        return (await getCurrentUser()) != null;
    } catch (error) {
        reportError(error);
        return false;
    }
};

const userSignOut = () => {
    signOut(auth)
        .then(() => {
            authState.authenticated = AUTH_UNKNOWN;
            setTelemetryUser(null);
            window.location.replace(`/${SIGN_IN_PATH}`);
        })
        .catch((error) => reportError(error));
};

export const useAuth = () => {
    return {
        authState,
        getCurrentUser,
        signIn,
        isUserSignedIn,
        userSignOut,
        isEmailSignInLink,
        requestEmailLink,
    };
};
