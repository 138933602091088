import { ref } from "vue";
import { defineStore } from "pinia";
import { PAYMENT_METHODS } from "../const/invoice";

export const useInvoiceStore = defineStore("invoice", () => {
    const invoice = ref({
        id: "",
        customer: {
            cms_number: 0,
            manager: "",
        },
        cardDetails: {},
        paymentMethod: PAYMENT_METHODS.CREDIT_CARD,
        billing: {
            subtotal: 0,
            gst: 0,
            total: 0,
        },
        jobs: [],
        payment_status: null,
    });
    const loading = ref(true);
    const error = ref(undefined);
    const statusCode = ref(undefined);

    function setInvoice(newInvoice) {
        if (newInvoice) {
            setInvoiceField("customer", newInvoice.customer);
            setInvoiceField("billing", newInvoice.billing);
            setInvoiceField("payment_status", newInvoice.payment_status);
            setInvoiceField("jobs", newInvoice.jobs);
        }
    }

    function setInvoiceField(field, value) {
        invoice.value[field] = value;
    }

    function setCustomerField(field, value) {
        invoice.value.customer[field] = value;
    }

    function setCreditCardField(field, value) {
        invoice.value.cardDetails[field] = value;
    }

    function setLoading(isLoading) {
        loading.value = isLoading;
    }

    function setError(e) {
        error.value = e;
    }

    function setStatusCode(code) {
        statusCode.value = code;
    }

    return {
        error,
        invoice,
        loading,
        statusCode,
        setError,
        setStatusCode,
        setInvoice,
        setInvoiceField,
        setCustomerField,
        setCreditCardField,
        setLoading,
    };
});
