<script setup>
import { useInvoiceStore } from "../../stores/invoice";

const invoiceStore = useInvoiceStore();
</script>

<template>
    <div class="flex justify-between">
        <div class="inline-flex">
            <h1 class="heading-text font-bold text-3xl">
                Invoice # {{ invoiceStore.invoice.id }}
            </h1>
        </div>
    </div>
</template>
